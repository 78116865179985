'use client';

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  Divider,
  useDisclosure,
  Image,
  Link,
  AspectRatio,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
// import LanguageButton from './languageButton';
import img from '../../image/logo.png';

import {
  FaDiscord,
  FaYoutube,
  FaTelegramPlane,
  FaInstagram,
  FaTwitter,
  FaShareSquare,
} from 'react-icons/fa';

export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box id="navbar">
      <Flex
        bg={'transparent'}
        color={'white'}
        minH={'60px'}
        py={{ base: 5 }}
        px={{ base: 2, md: 10, lg: 20, xl: 30, '2xl': 40 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={'rgba(255, 255, 255, 0.3) !important'}
        align={'center'}
        fontWeight={'500'}
        zIndex={'1'}
        width={'100%'}
      >
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            color={'#fffa2b'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex
          flex={{ base: 1 }}
          justify={{
            base: 'center',
            sm: 'center',
            md: 'space-between',
            lg: 'space-between',
            xl: 'space-between',
            '2xl': 'space-between',
          }}
        >
          <Link href="/">
            <AspectRatio minW={'150px'} ratio={2.9}>
              <Image
                src={img}
                alt="Logo"
                marginLeft={{
                  base: '-40px',
                  md: '0',
                  lg: '0',
                  xl: '0',
                  '2xl': '0',
                }}
              />
            </AspectRatio>
          </Link>

          <Flex display={{ base: 'none', md: 'flex' }} align={'center'} ml={10}>
            <DesktopNav />
          </Flex>
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={2}
            align={'center'}
          >
            {/* <LanguageButton /> */}
            <Button
              as={'a'}
              border={'solid 1px white'}
              borderRadius={'20px'}
              display={{ base: 'none', lg: 'inline-flex' }}
              fontSize={'md'}
              fontWeight={700}
              w={210}
              rightIcon={<Icon as={FaShareSquare} />}
              color={'white'}
              bg={'transparent'}
              href={'#'}
              _hover={{
                bg: '#ff0050',
                color: 'black',
                border: 'solid 1px transparent',
                transition: '0.3s ease',
              }}
            >
              Unipoly Game Studio
            </Button>
          </Stack>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            as={'a'}
            borderRadius={'20px'}
            fontSize={'md'}
            fontWeight={700}
            w={'90%'}
            rightIcon={<Icon as={FaShareSquare} />}
            color={'white'}
            bg={'#ff0050'}
            href={'#'}
          >
            Unipoly Game Studio
          </Button>
        </Box>
        <Divider borderColor={'rgba(255, 255, 255, 0.4) !important'} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkHoverColor = '#fffa2b';

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Box
                as="a"
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'md'}
                fontWeight={700}
                color={'white'}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
                {navItem.icon}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={'#555555'}
                p={3}
                rounded={'xl'}
                maxW="150px"
              >
                <Stack>
                  {navItem.children.map(child => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ icon, label, href, subLabel }) => {
  return (
    <Box
      as="a"
      href={href}
      role={'group'}
      display={'block'}
      p={0}
      rounded={'md'}
      _hover={{ bg: 'blackAlpha.300' }}
    >
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Stack direction={'row'} align={'center'}>
            <Text transition={'all .3s ease'} fontWeight={400} pl={1}>
              {icon}
            </Text>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: '#fffa2b' }}
              fontWeight={400}
              pb={1}
              color={'white'}
            >
              {label}
            </Text>
            <Text fontSize={'sm'} color={'white'}>
              {subLabel}
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Box>
      <Stack bg={'transparent'} p={4} justify={'space-between'}>
        {NAV_ITEMS.map(navItem => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
      </Stack>
    </Box>
  );
};

const MobileNavItem = ({ label, children, href, icon }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack direction={'column'} spacing={4} onClick={children && onToggle}>
      <Box
        display={'flex'}
        py={2}
        as="a"
        href={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text fontWeight={600} color={'white'}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
            color={'#fffa2b'}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}
        >
          {children &&
            children.map(child => (
              <Box
                as="a"
                key={child.label}
                py={2}
                href={child.href}
                icon={child.icon}
                color={'white'}
              >
                {child.icon}
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: ' Home',
    href: '/',
  },
  {
    label: ' Roadmap',
    href: 'roadmap',
  },
  {
    label: 'Whitepaper',
    href: '#',
    children: [
      {
        label: 'Türkçe',
        href: 'https://unipolycoin.com/WhitePaperTR.pdf',
      },
      {
        label: 'English',
        href: 'https://unipolycoin.com/WhitePaperEn.pdf',
      },
    ],
  },
  {
    label: ' Contact',
    href: 'contact',
  },
  {
    label: ' Community',
    children: [
      {
        icon: <Icon as={FaInstagram} />,
        label: '  Instagram',
        href: 'https://www.instagram.com/unipolycoin/',
      },
      {
        label: '  Twitter',
        href: 'https://twitter.com/unipolycoin',
        icon: <Icon as={FaTwitter} />,
      },
      {
        label: '  Telegram',
        href: ' https://t.me/unipolycoin',
        icon: <Icon as={FaTelegramPlane} />,
      },
      {
        label: '  Youtube',
        href: 'https://www.youtube.com/channel/UCpMiZbz3XZ8iPTa5YmEo-uQ',
        icon: <Icon as={FaYoutube} />,
      },
      {
        label: '  Discord',
        href: 'https://discord.com/invite/RWTgABaruQ',
        icon: <Icon as={FaDiscord} />,
      },
    ],
  },
];
