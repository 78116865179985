'use client';

import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  AspectRatio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { useInView, animated } from '@react-spring/web';
import React from 'react';
import './gamecard.css';
import raidf2 from '../../image/rf2.webp';

export default function Game() {
  // const [isOpen, setOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );

  return (
    <animated.div ref={ref} style={springs}>
      <Container maxW={'82rem'}>
        <Container className={'gameCard'} maxW={'7xl'} mb={'1.5%'} zIndex={0}>
          <Stack
            align={{ base: 'center', xl: 'start' }}
            spacing={{ base: 8, md: 28 }}
            py={{ base: 6, md: 5 }}
            direction={{ base: 'column', md: 'row' }}
            wrap={'wrap'}
          >
            <Stack flex={1} spacing={{ base: 5, md: 10 }}>
              <Heading
                textAlign={'justify'}
                ml={{ base: 0, md: '5%' }}
                py={{ base: 6, md: 5 }}
              >
                <Text
                  as={'span'}
                  position={'relative'}
                  fontSize={{ base: '40px' }}
                  color={'white'}
                >
                  RaidField 2
                </Text>
              </Heading>
              <Text
                mt={-10}
                color={'white'}
                ml={{ base: 0, md: '5%' }}
                fontWeight={'normal'}
              >
                Transform your gaming experience with RaidField 2! Battle it{' '}
                <br></br>
                out in the thrilling multiplayer arena and secure your victory.
                <br></br>
                Each completed mission doesn't just mean a win for your team,{' '}
                <br></br> but it also means earning UniPolyCoin - the currency
                of champions!
              </Text>
              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={{ base: 'column', sm: 'row' }}
                ml={{ base: 0, md: '5%' }}
                mt={10}
              >
                <Button
                  className="buttonShadow"
                  display="flex"
                  flexDirection="row"
                  rounded="20px"
                  size="lg"
                  fontWeight="normal"
                  px={8}
                  colorScheme="black"
                  border={'solid 2px white'}
                  bg={'transparent'}
                  leftIcon={<FaApple h={10} w={10} color="white" />}
                >
                  <span> App Store</span>
                </Button>
                <Button
                  className="buttonShadow"
                  display="flex"
                  flexDirection="row"
                  rounded="20px"
                  size="lg"
                  fontWeight="normal"
                  px={8}
                  border={'solid 2px white'}
                  colorScheme="black"
                  bg={'transparent'}
                  leftIcon={<FaGooglePlay h={2} w={2} color="white" />}
                >
                  <span> Google Play</span>
                </Button>
              </Stack>
            </Stack>
            <Flex
              flex={1}
              justify={'center'}
              align={'center'}
              position={'relative'}
              w={'full'}
            >
              <Box
                position={'relative'}
                width={'full'}
                overflow={'hidden'}
                justify={'center'}
                align={'center'}
              >
                <Image
                  src={raidf2}
                  onClick={onOpen}
                  maxH={'350px'}
                  mb={2}
                  rounded={'20px'}
                  loading="lazy"
                ></Image>
                <Modal isOpen={isOpen} size={'2xl'} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent
                    bg={
                      'radial-gradient(circle, rgba(71, 71, 71, 0.50024509803921573) 0%, rgba(0, 0, 0, 0.9243347338935574) 1.1%)'
                    }
                  >
                    <ModalHeader color={'white'}>RaidField 2</ModalHeader>
                    <ModalCloseButton color={'white'} />
                    <ModalBody>
                      <AspectRatio ratio={16 / 9}>
                        <iframe
                          width="100%"
                          height="100%"
                          src="https://www.youtube.com/embed/qNKPNfXvKZk?si=eQtmUsQwMkV_KzVK&amp;controls=0"
                          title="Raidfield2"
                          allowFullscreen
                        ></iframe>
                      </AspectRatio>
                    </ModalBody>

                    <ModalFooter>
                      <Button bg="#fff2ab" mr={3} onClick={onClose}>
                        Close
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Box>
            </Flex>
          </Stack>
        </Container>
      </Container>
    </animated.div>
  );
}
