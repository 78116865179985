'use client';

import { Box, Container, Flex } from '@chakra-ui/react';
import Card from './featurecard';
import { useInView, animated } from '@react-spring/web';

export default function Features() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );

  return (
    <animated.div ref={ref} style={springs}>
      <Box p={2} mt={'25px'} mb={'40px'}>
        <Container maxW={'7xl'} mt={0} align={'center'}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            <Card
              heading={'Circulating Supply'}
              description={'10,000,000'}
              href={'#'}
            />
            <Card
              heading={'Total Number of Users'}
              description={'2,000,000+'}
              href={'#'}
            />
            <Card
              heading={'Monthly Active Users'}
              description={'250000+'}
              href={'#'}
            />
            <Card
              heading={'Ready to Play Games'}
              description={'33'}
              href={'#'}
            />
          </Flex>
        </Container>
      </Box>
    </animated.div>
  );
}
