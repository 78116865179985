'use client';

import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  IconButton,
  Button,
  VStack,
  HStack,
  AspectRatio,
  Stack,
} from '@chakra-ui/react';
import { MdEmail, MdLocationOn } from 'react-icons/md';
import {
  FaDiscord,
  FaYoutube,
  FaTelegramPlane,
  FaInstagram,
  FaTwitter,
} from 'react-icons/fa';

export default function Contactbox() {
  return (
    <Container maxW="full" mt={0} centerContent overflow="hidden">
      <Flex>
        <Box
          bg="radial-gradient(circle, rgba(71, 71, 71, 0.30024509803921573) 0%, rgba(0, 0, 0, 0.5243347338935574) 91.1%)"
          color="white"
          borderRadius="20px"
          m={{ sm: 4, md: 16, lg: 2 }}
          p={{ sm: 5, md: 5, lg: '6rem 6rem' }}
        >
          <Box p={0}>
            <Stack
              direction={'row'}
              flexWrap={[
                'wrap',
                'wrap',
                'nowrap',
                'nowrap',
                'nowrap',
                'nowrap',
              ]}
              justifyContent={'center'}
            >
              <Box>
                <Flex
                  direction={'column'}
                  justifyContent={['center', 'center', 'center', 'flex-start']}
                  align={['center', 'center', 'center', 'flex-start']}
                >
                  <Heading ml={4}>Contact </Heading>

                  <Text mt={{ sm: 3, md: 3, lg: 5 }} color="white"></Text>
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack
                      pl={0}
                      spacing={3}
                      alignItems={['center', 'center', 'center', 'flex-start']}
                      justifyContent={[
                        'center',
                        'center',
                        'center',
                        'flex-start',
                      ]}
                    >
                      <Button
                        as={'a'}
                        size="md"
                        height="48px"
                        width="300px"
                        variant="ghost"
                        color="white"
                        border="2px solid"
                        borderColor="transparent"
                        borderRadius="20px"
                        _hover={{
                          border: '2px solid #FFB800',
                          borderRadius: '20px',
                        }}
                        leftIcon={<MdEmail color="white" size="20px" />}
                        justifyContent={'flex-start'}
                        href={'mailto:support@unipolycoin.com'}
                      >
                        support@unipolycoin.com
                      </Button>
                      <Button
                        size="md"
                        height="48px"
                        width="300px"
                        variant="ghost"
                        color="white"
                        border="2px solid"
                        borderColor="transparent"
                        borderRadius="20px"
                        _hover={{
                          border: '2px solid #FFB800',
                          borderRadius: '20px',
                        }}
                        leftIcon={<MdLocationOn color="white" size="20px" />}
                        justifyContent={'flex-start'}
                      >
                        İstanbul, Turkey
                      </Button>
                    </VStack>
                  </Box>
                  <HStack
                    mt={{ lg: 20, md: 10 }}
                    spacing={2}
                    px={0}
                    alignItems="flex-start"
                  >
                    <IconButton
                      as={'a'}
                      href={'https://discord.com/invite/RWTgABaruQ'}
                      aria-label="Discord"
                      variant="ghost"
                      size="lg"
                      color="white"
                      isRound={true}
                      _hover={{ bg: '#fffa2b' }}
                      icon={<FaDiscord size={'28px'} />}
                    />
                    <IconButton
                      as={'a'}
                      href={'https://www.instagram.com/unipolycoin/'}
                      aria-label="Instagram"
                      variant="ghost"
                      size="lg"
                      color="white"
                      isRound={true}
                      _hover={{ bg: '#fffa2b' }}
                      icon={<FaInstagram size={'28px'} />}
                    />
                    <IconButton
                      as={'a'}
                      href={'https://t.me/unipolycoin'}
                      aria-label="Telegram"
                      variant="ghost"
                      size="lg"
                      color="white"
                      isRound={true}
                      _hover={{ bg: '#fffa2b' }}
                      icon={<FaTelegramPlane size={'28px'} />}
                    />
                    <IconButton
                      as={'a'}
                      href={'https://twitter.com/unipolycoin'}
                      aria-label="Twitter"
                      variant="ghost"
                      size="lg"
                      color="white"
                      isRound={true}
                      _hover={{ bg: '#fffa2b' }}
                      icon={<FaTwitter size={'28px'} />}
                    />
                    <IconButton
                      as={'a'}
                      href={'https://www.youtube.com/@unipolycoin'}
                      aria-label="Youtube"
                      variant="ghost"
                      size="lg"
                      color="white"
                      isRound={true}
                      _hover={{ bg: '#fffa2b' }}
                      icon={<FaYoutube size={'28px'} />}
                    />
                  </HStack>
                </Flex>
              </Box>

              <AspectRatio
                w={'51vw'}
                minW={'300px'}
                maxH={'440px'}
                minH={'100px'}
              >
                <Box
                  bg="white"
                  borderRadius="20px"
                  display="flex"
                  flexDirection="column"
                  justifyContent={'center'}
                  alignContent={'center'}
                >
                  <AspectRatio w={'50vw'} minW={'300px'} minH={'100px'}>
                    <iframe
                      title="harita"
                      width={'100%'}
                      height={'100%'}
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d316.1751057985227!2d29.008420706745607!3d41.0693973989372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab667a3c764d9%3A0x16eaae49d43e99e1!2sDiffancy%20%2FFarkl%C4%B1%20olman%C4%B1n%20%C3%B6tesinde%20bir%20e-ticaret%20deneyimi!5e0!3m2!1str!2str!4v1698673273746!5m2!1str!2str"
                    />
                  </AspectRatio>
                </Box>
              </AspectRatio>
            </Stack>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
}
