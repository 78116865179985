import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Home from './pages/home';
import Contact from './pages/contact';
import Roadmap from './pages/roadmap';
import theme from './theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="roadmap" element={<Roadmap />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
