import React from 'react';

import './timeline.css';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';

import { ListIcon, List, ListItem, Text } from '@chakra-ui/react';
function Timeline() {
  return (
    <div>
      <ul className="timeline">
        {/* -----left---- */}

        <li className="timeline-event  ">
          <label className="timeline-event-iconL" />
          <div className="timeline-event-copyL">
            <p className="timeline-event-thumbnailL">Q4 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                Unipoly Chain Launch
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
            </List>
          </div>
        </li>

        {/* ------right----- */}
        <li className="timeline-event">
          <label className="timeline-event-iconR" />
          <div className="timeline-event-copyR">
            <p className="timeline-event-thumbnailR">Q3 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                Launch of Unipoly Marketplace
              </ListItem>
            </List>
          </div>
        </li>

        {/* -----left---- */}

        <li className="timeline-event  ">
          <label className="timeline-event-iconL" />
          <div className="timeline-event-copyL">
            <p className="timeline-event-thumbnailL">Q2 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                Listing in 2 Global CEX
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
              </ListItem>
            </List>
          </div>
        </li>

        {/* ------right----- */}
        <li className="timeline-event">
          <label className="timeline-event-iconR" />
          <div className="timeline-event-copyR">
            <p className="timeline-event-thumbnailR">Q1 2024</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                CEX Listings
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <ListIcon ml={2} mb={1} as={FaRegCircle} color="gray.400" />
                DEX Listings
              </ListItem>
            </List>
          </div>
        </li>

        {/* -----left---- */}

        <li className="timeline-event  ">
          <label className="timeline-event-iconL" />
          <div className="timeline-event-copyL">
            <p className="timeline-event-thumbnailL">Q4 2023</p>
            <List>
              <ListItem fontSize={'20px'} color="white">
                Launchpad at Bzetmex
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                <Text>
                  Launchpad at One Global Exchange
                  <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
                </Text>
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                First CEX Listing
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
              <ListItem fontSize={'20px'} color="white">
                Launch of User Rewards
                <ListIcon ml={2} as={FaCheckCircle} color="#28a745" />
              </ListItem>
            </List>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Timeline;
