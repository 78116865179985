import React from 'react';
import { Container } from '@chakra-ui/react';
import { FaYoutube, FaTelegramPlane, FaTwitter } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { SiDiscord } from 'react-icons/si';
import { useInView, animated } from '@react-spring/web';
import './community.css';

function Community() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );
  return (
    <animated.div ref={ref} style={springs}>
      <Container maxW={'82rem'}>
        <div className="cards">
          <div className="content">
            <AiFillInstagram size={40} color="white" />
            <h1>Instagram</h1>
            <a href="https://www.instagram.com/unipolycoin/">Follow</a>
          </div>
          <div className="content">
            <FaTwitter size={40} color="white" />
            <h1>Twitter</h1>
            <a href="https://twitter.com/unipolycoin">Follow</a>
          </div>
          <div className="content">
            <FaTelegramPlane size={40} color="white" />
            <h1>Telegram</h1>
            <a href="https://t.me/unipolycoin">Join</a>
          </div>
          <div className="content">
            <FaYoutube size={40} color="white" />
            <h1>Youtube</h1>
            <a href="https://www.youtube.com/@unipolycoin">Follow</a>
          </div>
          <div className="content">
            <SiDiscord size={40} color="white" />
            <h1>Discord</h1>
            <a href="https://discord.com/invite/RWTgABaruQ">Join</a>
          </div>
        </div>
      </Container>
    </animated.div>
  );
}

export default Community;
