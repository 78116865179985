/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Divider } from '@chakra-ui/react';
import React from 'react';
import Navbar from '../components/navbar/navbar';
import Hero from '../components/hero/hero';
import Features from '../components/features/features';
import Sponsors from '../components/sponsors/sponsors';
import Footer from '../components/footer/footer';
import Gamecontainer from '../components/game/gamecontainer';
import NewsSwiper from '../components/swiper/swiper';
import Timelinebox from '../components/timeline/timelinebox';
import Buybox from '../components/buybox/buybox';
import Community from '../components/community/community';

export default function Home() {
  return (
    <Box background={'#010810'}>
      <Navbar />
      <Hero />
      <Features />
      <Buybox />
      <Community />
      <Gamecontainer />
      <Timelinebox />
      <Sponsors />
      <NewsSwiper />
      <Divider borderColor={'rgba(255, 255, 255, 0.4) !important'} />
      <Footer />
    </Box>
  );
}
