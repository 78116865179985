import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Box,
  Heading,
  Image,
  Text,
  Stack,
  Container,
  Button,
  Center,
} from '@chakra-ui/react';
//import images
import expat from '../../image/news/expat.webp';
import fortune from '../../image/news/Fortune.webp';
import gamefi from '../../image/news/gamefi.webp';
import cumhuriyet from '../../image/news/cumhuriyet.webp';
import onuremir from '../../image/news/onuremir.webp';
import gamefi2 from '../../image/news/gamefi2.webp';
import gamefi3 from '../../image/news/gamefi3.webp';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './swiperstyle.css';
import { Autoplay, Pagination } from 'swiper/modules';
import { useInView, animated } from '@react-spring/web';

export default function NewsSwiper() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );
  return (
    <animated.div ref={ref} style={springs}>
      <Container maxW={'83rem'} mt={8} mb={10}>
        <Box overflow={'hidden'}>
          <Center>
            <Swiper
              slidesPerView={1}
              centeredSlides={false}
              loop={true}
              lazy={true}
              pagination={{
                dynamicBullets: true,
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              breakpoints={{
                480: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
                1536: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                },
              }}
              modules={[Pagination, Autoplay]}
              className="mySwiper"
            >
              {NEWS_ITEMS.map((newsItem, i) => {
                return (
                  <SwiperSlide key={i}>
                    <Box mb={10}>
                      <Box
                        background="radial-gradient(circle, rgba(71, 71, 71, 0.30024509803921573) 0%, rgba(0, 0, 0, 0.5243347338935574) 1.1%)"
                        h="350px"
                        maxW={'250px'}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        borderRadius={'20px'}
                        border={'solid 1px rgba(0,0,0,0)'}
                        p={3}
                        overflow={'hidden'}
                        transition={'all .3s ease'}
                        _hover={{
                          borderColor: '#fffa2b',
                          transition: 'all .3s ease',
                        }}
                      >
                        <Box
                          bg={'gray.100'}
                          mt={-6}
                          mx={-6}
                          mb={2}
                          pos={'relative'}
                          _hover={{
                            transform: 'scale(1.01)',
                            transition: 'transform 0.2s ease',
                          }}
                        >
                          <Image
                            src={newsItem.image}
                            fill
                            alt="NewsImage"
                            loading="lazy"
                            w={'275px'}
                            h={'155px'}
                          />
                        </Box>
                        <Stack justify={'flex-end'}>
                          <Heading
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            color={'white'}
                            fontSize={'md'}
                            textAlign={'left'}
                            minH={'58px'}
                          >
                            {newsItem.title}
                          </Heading>
                          <Text
                            color={'white'}
                            textAlign={'left'}
                            padding={'0px 0px'}
                            fontSize={'15px'}
                            lineHeight={'1.2'}
                            minH={'72px'}
                          >
                            {newsItem.description}
                          </Text>
                          <Button
                            as={'a'}
                            href={newsItem.href}
                            display="flex"
                            color={'white'}
                            mt={'5px'}
                            flexDirection="row"
                            width={'110px'}
                            border={'solid 1px rgb(194,194,194)'}
                            fontSize={'14px'}
                            backgroundColor={'transparent'}
                            borderRadius={'20px'}
                            _hover={{
                              backgroundColor: '#fffd8d55',
                              color: 'white',
                              border: 'solid 1px #fffa2b',
                              transition: 'color 0.3s ease',
                            }}
                          >
                            Read More
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Center>
        </Box>
      </Container>
    </animated.div>
  );
}

const NEWS_ITEMS = [
  {
    title:
      'Türk oyun şirketi,bağımsız geliştiricilerin oyunlarını Web3’e taşıyacak',
    description:
      'Oyun stüdyoları, geliştiriciler ve oyuncular için yeni bir ekonomik model oluşturan blokzinciri...',
    image: gamefi,
    href: 'https://www.itnetwork.com.tr/turk-oyun-sirketi-bagimsiz-gelistiricilerin-oyunlarini-web3e-tasiyacak/',
  },
  {
    title: 'Oyun Odaklı Kripto Para Birimlerinin Sayısı 500’e Yaklaştı',
    description:
      'Konuya ilişkin değerlendirmelerini paylaşan yerli oyun stüdyosu ve yayıncısı Unipoly Games’in...',
    image: fortune,
    href: 'https://www.fortuneturkey.com/oyun-odakli-kripto-para-birimlerinin-sayisi-500e-yaklasti',
  },
  {
    title: 'Turkish Game Company to Bring Indie Developers Games to Web3',
    description:
      'Emir Emad said, “Our Raidfield 2 game, which we developed as Unipoly Games, reached more than 2 million downloads...',
    image: expat,
    href: 'https://expatguideturkey.com/turkish-game-company-to-bring-indie-developers-games-to-web3/',
  },
  {
    title: 'Unipoly Games, oyunları Web 3.0 ortamına taşıyacağını açıkladı.',
    description:
      'Unipoly Games CEO’su Emir Amad açıklamasında, “Pek çok sektörde konuşulduğu gibi, oyun sektöründe de...',
    image: gamefi2,
    href: 'https://www.cumhuriyet.com.tr/bilim-teknoloji/bagimsiz-gelistiricilerin-oyunlarini-web3e-tasiyacak-2118516',
  },
  {
    title:
      'Blokzinciri oyun pazarı 2028’de 117 milyar dolar büyüklüğe ulaşacak',
    description:
      'Blokzinciri teknolojisinde gelişmelerin sayesinde NFT, Web 3.0 gibi kavramlar video oyun ekosistemine...',
    image: gamefi3,
    href: 'https://www.milliyet.com.tr/teknoloji/turk-oyun-sirketi-oyunlari-web3e-tasiyacak-7004293',
  },

  {
    title: 'Unipoly Games Web3’ün kapılarını Türkiye’ye açıyor.',
    description:
      'Türkiye’de bir ilki gerçekleştirerek, yerli oyun stüdyolarının oyunlarını, Unipoly Coin adını...',
    image: onuremir,
    href: 'https://www.dha.com.tr/teknoloji/turk-oyun-sirketi-bagimsiz-gelistiricilerin-oyunlarini-web3e-tasiyacak-2310704',
  },

  {
    title: 'Kuluçka merkezi gibi çalışarak Türk oyunlarını destekliyoruz',
    description:
      '“Bir kuluçka merkezi rolü üstleniyoruz. Bize başvuran tüm Oyun stüdyolarının oyun üretme kapasitelerini...',
    image: cumhuriyet,
    href: 'https://www.ntv.com.tr/turkiye/turk-oyun-sirketi-bagimsiz-gelistiricilerin-oyunlarini-web3e-tasiyacak,OMAy6d3x4k6OWaeLWmijaQ',
  },
];
