import React from 'react';
import Marquee from 'react-fast-marquee';
import { Image, Container } from '@chakra-ui/react';
import cmc from '../../image/sponsors/cmc.webp';
import coingecko2 from '../../image/sponsors/coingecko.webp';
import eth2 from '../../image/sponsors/ethereum.webp';
import coinw from '../../image/sponsors/coinw.webp';
import hyperbc from '../../image/sponsors/hyperbc.webp';
import bzetmex from '../../image/sponsors/bzetmex.webp';
import hyperpay from '../../image/sponsors/hyperpay.webp';
import { useInView, animated } from '@react-spring/web';
import './sponsors.css';
function Sponsors() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );

  return (
    <animated.div ref={ref} style={springs}>
      <Container maxW={'82rem'} p={'40px 0px 40px 0px'} mt={'2%'}>
        <Marquee
          gradient={true}
          gradientColor="rgb(1,8,16)"
          gradientWidth={100}
        >
          <Image className="sponsor-image" loading="lazy" src={cmc} />
          <Image className="sponsor-image" loading="lazy" src={coingecko2} />
          <Image className="sponsor-image" loading="lazy" src={eth2} />
          <Image className="sponsor-image" loading="lazy" src={coinw} />
          <Image className="sponsor-image" loading="lazy" src={hyperpay} />
          <Image className="sponsor-image" loading="lazy" src={bzetmex} />
          <Image className="sponsor-image" loading="lazy" src={hyperbc} />
          <Image className="sponsor-image" loading="lazy" src={cmc} />
          <Image className="sponsor-image" loading="lazy" src={coingecko2} />
          <Image className="sponsor-image" loading="lazy" src={eth2} />
          <Image className="sponsor-image" loading="lazy" src={coinw} />
          <Image className="sponsor-image" loading="lazy" src={bzetmex} />
          <Image className="sponsor-image" loading="lazy" src={hyperpay} />
        </Marquee>
      </Container>
    </animated.div>
  );
}

export default Sponsors;
